<template>
  <master-layout
    smallTitle="il tuo"
    bigTitle="Profilo"
    :titleStyle="
      whappyClientConfig.name == 'Axerta' ? 'horizontal' : 'vertical'
    "
    :showInfoPopHover="true"
    :infoPopHover="
      whappyClientConfig.name == 'Yoube'
        ? INFOPOPOVER_MAP[whappyClientConfig.name]
        : INFOPOPOVER_MAP['default']
    "
  >
    <template v-if="user">
      <ion-row>
        <ion-card class="ion-no-margin ion-no-padding no-background">
          <ion-card-header class="ion-no-margin ion-no-padding">
            <ion-row class="ion-justify-content-between ion-align-items-center">
              <ion-col>
                <ion-row>
                  <ion-col size="12">
                    <div class="user-picture">
                      <img
                        :src="user.data.avatar_url"
                        alt="Profile picture"
                        @error="handleImageError"
                      />
                    </div>

                    <avatar-cropper
                      :trigger="changeAvatarTrigger"
                      :labels="{
                        submit: $t('account.change_avatar.submit'),
                        cancel: $t('account.change_avatar.cancel'),
                      }"
                      :upload-handler="handleUpload"
                    />
                  </ion-col>
                </ion-row>
              </ion-col>
              <ion-col size="6">
                <ion-row
                  class="ion-align-items-center ion-justify-content-center"
                >
                  <ion-col size="4">
                    <!-- <img src="" alt="award picture" @errror="handleImageError" /> -->
                  </ion-col>
                </ion-row>
                <ion-row
                  v-if="user.id_livello"
                  class="ion-align-items-center ion-justify-content-center"
                >
                  <ion-col size="10">
                    <ion-row class="ion-justify-content-center">
                      <p>Livello</p>
                      <p>
                        <strong>{{ user.id_livello }}</strong>
                      </p>
                    </ion-row>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <ion-row class="w-100">
              <ion-col size="4">
                <button @click="triggerAvatar()" class="p-sm button-primary">
                  {{ $t("account.change_avatar") }}
                </button>
              </ion-col>
              <ion-col size="4">
                <button @click="goToChangePassword" class="p-sm button-primary">
                  Cambia password
                </button>
              </ion-col>
              <ion-col size="4">
                <button
                  @click="handleCambiaDisponibilita()"
                  class="p-sm button-primary"
                  id="open-axerta-disponibilita"
                  expand="block"
                >
                  Gestisci disponibilità
                </button>
              </ion-col>
            </ion-row>
          </ion-card-header>
          <ion-card-content class="ion-no-margin ion-no-padding">
            <ion-row class="ion-padding-start ion-padding-top">
              <ion-col size="6">
                <h3>First name</h3>
                <p>{{ user.data.nome }}</p>
              </ion-col>
              <ion-col size="6">
                <h3>Last name</h3>
                <p>{{ user.data.cognome }}</p>
              </ion-col>

              <ion-col size="6">
                <h3>Email</h3>
                <p>{{ user.data.email }}</p>
              </ion-col>
              <ion-col size="6">
                <h3>Company</h3>
                <p>{{ user.data.company }}</p>
              </ion-col>
              <ion-col size="6">
                <h3>Role</h3>
                <p>{{ user.data.role }}</p>
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>

        <ion-card class="no-background">
          <ion-row @click="expandItem('privacy')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.privacy"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">Privacy</h3>
            </ion-col>
          </ion-row>
          <template v-if="expanded.privacy">
            <a
              class="py-4 pl-4 mt-4"
              href="https://www.iubenda.com/privacy-policy/737204"
              target="_blank"
              >Documento Privacy</a
            >

            <div class="flex pl-4 py-3">
              <ion-checkbox v-model="privacy_accepted"></ion-checkbox>
              <ion-label class="pl-2"
                >Ho letto e accetto il Documento Privacy</ion-label
              >
            </div>

            <div class="privacy-content py-2 pl-4">
              <div class="flex flex-column">
                <span>Submission</span>
                <span class="font-bold">{{
                  user.data.nome + " " + user.data.cognome
                }}</span>
              </div>

              <div class="flex flex-column">
                <span>Data</span>
                <span class="font-bold">{{
                  new Date(user.data.dataPrivacy).toLocaleDateString("it", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  })
                }}</span>
              </div>

              <div class="flex flex-column">
                <span>Ora</span>
                <span class="font-bold">
                  {{
                    new Date(user.data.dataPrivacy).toLocaleTimeString("it", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}
                </span>
              </div>
            </div>
          </template>
          <div class="last-child"></div>
        </ion-card>

        <ion-card
          v-if="whappyClientConfig.name == 'Fattoria Dei Talenti'"
          class="no-background"
          id="risultato"
        >
          <ion-row @click="expandItem('attivita')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.attivita"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">Risultato</h3>
            </ion-col>
          </ion-row>

          <template v-if="expanded.attivita">
            <div
              class="ion-padding-bottom"
              v-if="$root.translate(user.role_attivita)"
            >
              <div v-for="item in textsNewLines.attivita" :key="item">
                {{ item }}<br />
              </div>
            </div>
            <div v-else>Non è registrata il risultato</div>
          </template>
          <div class="last-child"></div>
        </ion-card>
        <ion-card v-else class="no-background" id="missione">
          <ion-row @click="expandItem('missione')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.missione"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">Missione</h3>
            </ion-col>
          </ion-row>

          <template v-if="expanded.missione">
            <div
              class="ion-padding-bottom"
              v-if="$root.translate(user.data.role_mission)"
            >
              <div v-for="item in textsNewLines.mission" :key="item">
                {{ item }}<br />
              </div>
            </div>
            <div v-else>Non è registrata la missione</div>
          </template>
          <div class="last-child"></div>
        </ion-card>

        <ion-card
          v-if="whappyClientConfig.name == 'Fattoria Dei Talenti'"
          class="no-background"
          id="funzioni"
        >
          <ion-row @click="expandItem('mission')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.mission"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">Funzioni</h3>
            </ion-col>
          </ion-row>

          <template v-if="expanded.mission">
            <div
              class="ion-padding-bottom"
              v-if="$root.translate(user.data.role_mission)"
            >
              <div v-for="item in textsNewLines.mission" :key="item">
                {{ item }}<br />
              </div>
            </div>
            <div v-else>Non sono registrate funzioni</div>
          </template>
          <div class="last-child"></div>
        </ion-card>
        <ion-card
          v-else-if="whappyClientConfig.name != 'Yoube'"
          class="no-background"
          id="responsabilita"
        >
          <ion-row @click="expandItem('responsabilita')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.responsabilita"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">Responsabilità e Compiti</h3>
            </ion-col>
          </ion-row>

          <template v-if="expanded.responsabilita">
            <div
              class="ion-padding-bottom"
              v-if="$root.translate(user.data.role_respcompiti)"
            >
              <div v-for="item in textsNewLines.respcompiti" :key="item">
                {{ item }}<br />
              </div>
            </div>
            <div v-else>Non sono registrate responsabilità o compiti</div>
          </template>
          <div class="last-child"></div>
        </ion-card>

        <ion-card
          v-if="whappyClientConfig.name == 'Fattoria Dei Talenti'"
          class="no-background"
          id="desc-operativa-funzioni"
        >
          <ion-row @click="expandItem('responsabilita')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.responsabilita"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">Desc. Operativa Funzioni</h3>
            </ion-col>
          </ion-row>

          <template v-if="expanded.responsabilita">
            <div
              class="ion-padding-bottom"
              v-if="$root.translate(user.data.role_respcompiti)"
            >
              <!-- <div v-for="item in textsNewLines.respcompiti" :key="item">
                {{ item }}<br>
              </div> -->
              <span style="white-space: pre-line">{{
                user.data.role_respcompiti["it-IT"]
              }}</span>
            </div>
            <div v-else>
              Non è registrata una descrizione operativa delle funzioni
            </div>
          </template>
        </ion-card>
        <ion-card
          v-else-if="whappyClientConfig.name != 'Yoube'"
          class="no-background"
          id="attivita"
        >
          <ion-row @click="expandItem('attivita')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.attivita"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">Attività</h3>
            </ion-col>
          </ion-row>

          <template v-if="expanded.attivita">
            <div
              class="ion-padding-bottom"
              v-if="$root.translate(user.data.role_attivita)"
            >
              <div v-for="item in textsNewLines.attivita" :key="item">
                {{ item }}<br />
              </div>
            </div>
            <div v-else>Non sono registrate attività</div>
          </template>
        </ion-card>

        <!-- ORGANIGRAMMA -->
        <ion-card class="no-background" id="organigramma">
          <ion-row @click="expandItem('organigramma')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.organigramma"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">I miei referenti, organigramma</h3>
            </ion-col>
          </ion-row>

          <ion-row v-show="expanded.organigramma">
            <ion-col>
              <ion-row v-if="tutorNome" class="ion-align-items-center">
                <ion-col class="tutor-avatar">
                  <img
                    v-if="tutorAvatar"
                    :src="tutorAvatar"
                    class="avatar"
                    @error="handleImageError"
                  />
                  <ion-icon
                    v-else
                    class="icon-anon-user avatar"
                    :icon="personOutline"
                  ></ion-icon>
                </ion-col>
                <ion-col>
                  <p><strong>Tutor: </strong>{{ tutorNome }}</p>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>

          <network
            :class="{ 'organidramma-visible': !expanded.organigramma }"
            :id="user.id || user.data.id || user.data.ID"
            :company="user.data.id_company"
          >
          </network>
        </ion-card>

        <!-- KPI -->
        <ion-card
          v-if="whappyClientConfig.name == 'People Onboard'"
          class="no-background"
          id="kpi"
        >
          <ion-row @click="expandItem('kpi')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.kpi"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">I miei KPI annuali</h3>
            </ion-col>
          </ion-row>

          <template v-if="expanded.kpi">
            <kpi-graph
              :prop_dataSet="[
                { label: 'EBITDAR', data: kpi },
                {
                  label: 'GRI- INDEX',
                  data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                },
              ]"
              :labelsValues="[
                'Gen',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dic',
              ]"
              :prop_options="{}"
            ></kpi-graph>
          </template>
          <div class="last-child"></div>
        </ion-card>

        <!-- AWARDS -->
        <ion-card
          v-if="whappyClientConfig.name != 'Axerta'"
          class="no-background"
          id="awards"
        >
          <!-- <ion-row @click="expanded.awards = !expanded.awards"> -->
          <ion-row @click="expandItem('awards')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.awards"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">Awards vinti</h3>
            </ion-col>
          </ion-row>

          <template v-if="expanded.awards">
            <awards-slider :awardsList="wonAwards"></awards-slider>
          </template>
          <div class="last-child"></div>
        </ion-card>

        <!-- ANALISI DEL TALENTO -->
        <ion-card
          v-if="
            whappyClientConfig.name != 'People Onboard' &&
            whappyClientConfig.name != 'Axerta' &&
            whappyClientConfig.name != 'Yoube'
          "
          class="no-background"
          id="analisiDelTalento"
        >
          <ion-row @click="expandItem('analisiDelTalento')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.analisiDelTalento"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">Analisi del talento</h3>
            </ion-col>
          </ion-row>

          <div v-if="expanded.analisiDelTalento">
            <analisi-del-talento
              :prop_analisiDelTalento="analisiDelTalento"
              :prop_showGraph="showGraph"
              :prop_user_img="user.data.avatar_url"
              :colors="colorsAnalisiDelTalento"
            >
            </analisi-del-talento>
          </div>
        </ion-card>

        <ion-card
          v-if="whappyClientConfig.name == 'People Onboard'"
          class="no-background"
          id="risultatoSurvey"
        >
          <ion-row @click="expandItem('risultatoSurvey')">
            <ion-col size="auto">
              <ion-icon
                v-if="expanded.risultatoSurvey"
                class="icon-expander"
                :icon="chevronUpOutline"
              ></ion-icon>
              <ion-icon
                v-else
                class="icon-expander"
                :icon="chevronDownOutline"
              ></ion-icon>
              <h3 class="h3-expandable">Risultato Survey</h3>
            </ion-col>
          </ion-row>

          <template v-if="expanded.risultatoSurvey">
            <polar-area-chart
              :pallette="[
                '#646363',
                '#f39200',
                '#1C1F3D',
                '#1C1C1C',
                '#f39200',
              ]"
              :labelsData="[
                'Survey 1',
                'Survey 2',
                'Survey 3',
                'Survey 4',
                'Survey 5',
              ]"
            ></polar-area-chart>
          </template>
          <div class="last-child"></div>
        </ion-card>
      </ion-row>
    </template>
  </master-layout>
</template>

<script>
import {
  modalController,
  IonGrid,
  IonicSwiper,
  IonAvatar,
  IonCardTitle,
  IonButton,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonCheckbox,
  alertController,
} from "@ionic/vue";
import {
  personOutline,
  chevronDownOutline,
  chevronUpOutline,
} from "ionicons/icons";

import { ref, resolveDirective } from "vue";
import ApiService from "../../common/service.api";
import MasterLayout from "../../components/MasterLayout";
import { useRoute } from "vue-router";
import { translate } from "../../common/helper.auth";
import UserService from "../../common/mixins/User";

import AvatarCropper from "../../components/AvatarCropper";
import { AVATAR_PLACEHOLDER_URL } from "@/common/config";

import Network from "../../components/Network.vue";
import KpiGraph from "../../components/KpiGraph.vue";
import "../../components/chart.js";
import AwardsSlider from "../../components/AwardsSlider.vue";
import AnalisiDelTalento from "../../components/AnalisiDelTalento.vue";
import PolarAreaChart from "../../components/PolarAreaChart.vue";
import AxertaDisponibilita from "@/views/Modals/AxertaDisponibilita.vue";

import { useStore } from "vuex";

export default {
  name: "Account",
  components: {
    IonGrid,
    IonAvatar,
    IonCardTitle,
    IonButton,
    MasterLayout,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonIcon,
    IonItem,
    IonLabel,
    AvatarCropper,
    IonCheckbox,

    Network,
    KpiGraph,
    AwardsSlider,
    AnalisiDelTalento,
    PolarAreaChart,
  },
  mixins: [UserService],

  async ionViewWillEnter() {
    const route = useRoute();
    this.loadUser();

    this.currentUser = useStore().state.user.userData;

    if (this.whappyClientConfig.name == "Axerta") {
      await this.getCurrentUserAxertaData();
    }
  },

  data() {
    return {
      currentUser: null,

      privacy_accepted: true,

      chevronUpOutline,
      chevronDownOutline,
      personOutline,
      user: null,
      changeAvatarTrigger: false,
      wonAwards: [],

      kpi: [12, 16, 17, 17, 18, 19, 16, 15, 14, 12, 12, 15],

      tutorNome: "",
      tutorAvatar: "",
      expanded: {
        missione: false,
        responsabilita: false,
        attivita: false,
        organigramma: false,
        kpi: false,
        awards: false,
        analisiDelTalento: false,
        risultatoSurvey: false,
      },

      analisiDelTalento: null,
      showGraph: null,

      textsNewLines: {
        mission: [],
        respcompiti: [],
        attivita: [],
      },
      INFOPOPOVER_MAP: {
        Yoube:
          "In questa sezione le tue informazioni di profilo e i riconoscimenti e premi che hai ottenuto. Qui puoi reimpostare la password e cambiare la tua foto profilo.",
        default:
          "In questa sezione le tue informazioni di profilo e i riconoscimenti e premi che hai ottenuto. Qui puoi reimpostare la password e cambiare la tua foto profilo.",
      },
      loadingAxertaData: false,
      cieSkills: null,
    };
  },

  methods: {
    async handleCambiaDisponibilita() {
      const modal = await modalController.create({
        component: AxertaDisponibilita,
        cssClass: "my-custom-class",
        componentProps: {
          idCie: this.currentUser.id || useStore().state.user.userData.id,
        },
      });

      modal.onDidDismiss().then(() => {
        this.getCurrentUserAxertaData();
      });
      return modal.present();
    },
    async getCurrentUserAxertaData() {
      this.loadingAxertaData = true;
      ApiService.get(
        "DossierManager/GetCieSkills/" + this.currentUser.id ||
          useStore().state.user.userData.id
      )
        .then((res) => {
          this.cieSkills = res.data;
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: Account.vue:712 ~ getCurrentUserAxertaData ~ error:",
            error
          );
        })
        .finally(() => {
          this.loadingAxertaData = false;
        });
    },
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    goToChangePassword() {
      this.$router.push("/changepassword");
    },
    getAnalisiDelTalento() {
      ApiService.get("AnalisiDelTalentoByUser/" + this.user.data.id)
        .then((res) => {
          this.analisiDelTalento = JSON.parse(JSON.stringify(res.data));
          this.showGraph = true;
        })
        .catch(() => {
          this.showGraph = false;
          console.error("Questo utente non ha un analisi del talento");
        });
    },
    expandItem(string) {
      console.log("🚀 ~ file: Account.vue:712 ~ expandItem ~ string:", string);
      this.expanded[string] = !this.expanded[string];

      setTimeout(() => {
        document
          .querySelector("#" + string)
          .lastChild.scrollIntoView({ behavior: "smooth" });
      }, 500);
    },
    setTutor(father) {
      this.tutorNome = father.nome + " " + father.cognome;
      this.tutorAvatar = father.avatar_url;
      if (!this.tutorAvatar) {
        this.tutorAvatar = AVATAR_PLACEHOLDER_URL;
      }
    },
    loadUser() {
      const that = this;
      let user = ref({});
      // let userId = ref(11);
      const apiGet = () => {
        that.getCurrentUser({ forceRefresh: true }).then((data) => {
          that.user = data;
          getFather();
          getWonAwards();
          createTextNewLines();
          this.getAnalisiDelTalento();
        });
      };
      const getWonAwards = () => {
        this.wonAwards.splice(0);
        ApiService.get("CampaignManager/GetUserAward/").then((res) => {
          res.data.forEach((element) => {
            this.wonAwards.push(element);
          });
        });
      };

      const getFather = () => {
        if (this.user.data.id_parent) {
          ApiService.get("User/" + this.user.data.id_parent).then((res) => {
            this.setTutor(res.data);
          });
        }
      };

      const createTextNewLines = () => {
        this.textsNewLines.mission.length = 0;
        this.textsNewLines.respcompiti.length = 0;
        this.textsNewLines.attivita.length = 0;

        translate(this.user.data.role_mission)
          .split("\n")
          .forEach((element) => {
            this.textsNewLines.mission.push(element);
          });
        translate(this.user.data.role_respcompiti)
          .split("\n")
          .forEach((element) => {
            this.textsNewLines.respcompiti.push(element);
          });
        translate(this.user.data.role_attivita)
          .split("\n")
          .forEach((element) => {
            this.textsNewLines.attivita.push(element);
          });
      };

      apiGet();
    },
    triggerAvatar() {
      this.changeAvatarTrigger = true;
      setTimeout(() => {
        this.changeAvatarTrigger = false;
      }, 1);
    },
    handleUpload(cropper) {
      const that = this;
      cropper
        .getCroppedCanvas({
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false,
          maxWidth: 1000,
          maxHeight: 1000,
        })
        .toBlob(
          async (blob) => {
            const form = new FormData();

            form.append("file", blob, "image.jpg");

            ApiService.post("Upload", form, {
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then((res) => {
                if (res) {
                  ApiService.put("UserUtility/SetProfileImage", {
                    filePath: res.data,
                  }).then((res2) => {
                    that.loadUser();
                  });
                }
              })
              .catch((err) => {
                console.log("FILE NOT UPLOADED");
                console.log(err);
              });
          },
          this.outputMime || this.mimeType,
          this.outputQuality
        );
    },
  },
  computed: {
    colorsAnalisiDelTalento() {
      return [
        this.whappyClientConfig.primaryColor,
        this.whappyClientConfig.secondaryColor,
        this.whappyClientConfig.thirdaryColor,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
ion-card {
  width: 100%;
}

.icon-anon-user {
  padding: 0.25rem;
  font-size: 2rem;
  color: grey;
}

h3,
p,
strong {
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 1.2rem;
  color: var(--ion-color-primary);
}

.h3-expandable {
  padding-left: 30px;
}

ion-card h3 {
  font-weight: 600;
}

.user-picture {
  width: 110px;
  height: 110px;
}

.user-picture img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.tutor-avatar {
  max-width: 5rem;
}

.tutor-avatar img {
  border-radius: 50%;
}

.icon-expander {
  position: absolute;
  top: 5px;
  font-size: 1.5rem;
}

.organidramma-visible,
.last-child {
  height: 0;
}
.privacy-content {
  display: grid;
  grid-template-columns: 6fr 3fr 3fr;
}
</style>
